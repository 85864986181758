import React from "react";
import { graphql } from 'gatsby'
import { resetWebflow, sortRecords } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import PreviewVideo from "../components/video-demo/previewVideo";
import Notification from "../components/header/notification"
import { Pagination } from "../utils/pagination";
import ReactPaginate from 'react-paginate';
import Layout from "../layouts/headerFooterOnly";

export class VideoDemoPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      data: {
        allDatoCmsDemo: { edges: videos }
      }
    } = this.props;
    sortRecords(videos);
    let paging = new Pagination(videos, 21);
    this.state = {
      paging,
      data: paging.pageChanged()
    }
  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      data: this.state.paging.pageChanged(page)
    })
  };

  render() {
    let {
      data: {
        datoCmsVideoDemo: { seoMetaTags, schema, title, subtitle, notificationPage },
        allDatoCmsNotification: { edges: notifications }
      }
    } = this.props;
    resetWebflow("5a8ba2b2d906b1000190611d");

    return (
      <Layout>
        <Schema data={schema} />
        <HelmetDatoCms seo={seoMetaTags} />
        <Notification notifications={notifications} linkedPage={notificationPage}></Notification>
        <header className="main-header about">
          <div className="container" />
          <div className="container grid">
            <h1 className="h-nofixedwidth">{title}</h1>
            <div className="sm-spacing" />
            <p className="p center-align">{subtitle}</p>
            <div className="md-spacing" />
            <div className="grid-container-3">
              {this.state.data.map(({ node }) => {
                return <PreviewVideo data={node} />;
              })}
            </div>
            <div className="md-spacing"></div>
            {this.state.paging.isShowPagination &&  
              <ReactPaginate                
                previousLabel={''}
                nextLabel={''}      
                pageClassName={'page-number'}  
                breakLabel={'...'} 
                breakClassName={'page-number'}
                pageCount={this.state.paging.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            }
          </div>
        </header>
      </Layout>
    );
  }
};

export default VideoDemoPage;

export const query = graphql`
  query VideoDemosQuery {
    datoCmsVideoDemo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
      title
      subtitle
      notificationPage {
        id
        page
      }
    }
    allDatoCmsDemo {
      edges {
        node {
          id
          slug
          thumbnail {
            alt
            url
          }
          title
          subtitle
          position
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
