import React from 'react'
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import { resizeAndConvertImage } from '../../utils/common';

export default ({ data }) => {
    return (
        <Link to={`/videos/${data.slug}`} className="shadow-box videos w-inline-block">
            <div className="video-container">
                <div className="overlay-video" />
                <img src={`${data.thumbnail.url}${resizeAndConvertImage(760)}`} width="350" className="video-thumb" alt={data.thumbnail.alt}/>
            </div>
            <div className="platform-detail videos">
                <h6 className="h6">{data.title}</h6>
                <div className="sm-spacing" />
                <div className="small-copy">
                    <ReactMarkdown source={data.subtitle} escapeHtml={false} />
                </div>
            </div>
        </Link>
    );
}